<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p
            class="user-name font-weight-bolder mb-0"
            style="max-width: 200px; text-overflow: ellipsis; overflow: hidden"
          >
            {{ userData.name || "" }}
          </p>
          <span v-if="userData.role && userData.role.name" class="user-role">{{
            userData.role.name || ""
          }}</span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          class="border border-white"
        >
          <feather-icon v-if="!userData.avatar" icon="UserIcon" size="22" />
        </b-avatar>
      </template>

      <b-dropdown-item
        :to="{ name: 'profile' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Thông tin TK</span>
      </b-dropdown-item>

      <b-dropdown-item v-b-modal.modal-change-password>
        <feather-icon size="16" icon="LockIcon" class="mr-50" />
        <span>Đổi mật khẩu</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        :to="{ name: 'jobs' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="UploadIcon" class="mr-50" />
        <span>Lịch sử import</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'system-logs' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="AlertTriangleIcon" class="mr-50" />
        <span>Lịch sử tác động</span>
      </b-dropdown-item>
      <b-dropdown-divider />

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50 text-danger" />
        <span class="text-danger">Đăng xuất</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <change-password-modal />
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  VBModal,
  BDropdownDivider,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import ChangePasswordModal from "@/views/profile/view/ChangePasswordModal.vue";
import { getUserRole } from "@/auth/utils";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BDropdownDivider,
    ChangePasswordModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  methods: {
    logout() {
      const role = getUserRole();
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({
        name:
          role && role.typeId === "admin" ? "auth-admin-login" : "auth-login",
      });
    },
  },
  setup() {
    const { appName } = $themeConfig.app;

    return {
      appName,
    };
  },
};
</script>
