export default [
  {
    header: 'Cài đặt',
    resource: 'setting',
    action: 'read',
  },
  {
    title: 'Danh sách tài khoản',
    icon: 'CreditCardIcon',
    route: 'tingees',
    resource: 'bank',
    action: 'read',
  },
  {
    title: 'Zalo OA',
    icon: 'MessageSquareIcon',
    route: 'zalo',
    resource: 'zalo',
    action: 'read',
  },
  {
    title: 'Mẫu in hóa đơn',
    icon: 'SlidersIcon',
    route: 'templates',
    resource: 'template',
    action: 'read',
  },
  {
    title: 'Cài đặt khác',
    icon: 'SettingsIcon',
    route: 'setting',
    resource: 'general-setting',
    action: 'read',
  },

];
