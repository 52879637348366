export default [
  {
    header: 'Báo cáo thống kê',
    resource: 'report',
    action: 'read',
  },
  {
    title: 'Thống kê khoản thu',
    route: 'report-by-service-type',
    icon: 'BarChartIcon',
    resource: 'report-by-service-type',
    action: 'read',
  },
];
